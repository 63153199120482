import React, { Component } from 'react';
import {    Segment, Icon,  Button, Dimmer, Loader, Menu, Select } from 'semantic-ui-react';
import Grafico from './grafico'

import PubSub from 'pubsub-js';

class Main extends Component {

  constructor(props){
    super(props);
      this.state = {
       
        
        anos:[{key:'2018',value:'2018',text:'2018'},
              {key:'2019',value:'2019',text:'2019'}],
        meses:[{key:'JANEIRO',value:'JANEIRO',text:'JANEIRO'},
               {key:'FEVEREIRO',value:'FEVEREIRO',text:'FEVEREIRO'},
               {key:'MARÇO',value:'MARÇO',text:'MARÇO'},
               {key:'ABRIL',value:'ABRIL',text:'ABRIL'},
               {key:'MAIO',value:'MAIO',text:'MAIO'},
               {key:'JUNHO',value:'JUNHO',text:'JUNHO'},
               {key:'JULHO',value:'JULHO',text:'JULHO'},
               {key:'AGOSTO',value:'AGOSTO',text:'AGOSTO'},
               {key:'SETEMBRO',value:'SETEMBRO',text:'SETEMBRO'},
               {key:'OUTUBRO',value:'OUTUBRO',text:'OUTUBRO'},
               {key:'NOVEMBRO',value:'NOVEMBRO',text:'NOVEMBRO'},
               {key:'DEZEMBRO',value:'DEZEMBRO',text:'DEZEMBRO'}],
        filiais:[{key:'302030',value:'302030',text:'FRUTAL'}],
        
        indicadores:[{key:'malteimperiopilsen',value:'malteimperiopilsen',text:'Malte Império Pilsen | kg/hl', diario: [], mensal:[], active:false},
                    // {key:'malteimperiogoldlager',value:'malteimperiogoldlager',text:'Malte Gold / Lager | kg/hl', diario: [], mensal:[], active:false},
                     {key:'terra',value:'terra',text:'Terra | g/hl', diario: [], mensal:[], active:false},
                    // {key:'matufast',value:'matufast',text:'Matufast | g/hl', diario: [], mensal:[], active:false},
                    // {key:'lupulopilsen',value:'lupulopilsen',text:'Lúpulo Pilsen | kg/hl (a.a.)', diario: [], mensal:[], active:false},
                    // {key:'lupulogoldlager',value:'lupulogoldlager',text:'Lúpulo Gold / Lager | kg/hl (a.a.)', diario: [], mensal:[], active:false},
                    // {key:'oximb',value:'oximb',text:'OXI-MB | g/hl', diario: [], mensal:[], active:false},
                    // {key:'daraclar',value:'daraclar',text:'Daraclar | g/hl', diario: [], mensal:[], active:false},
                    // {key:'pvpp',value:'pvpp',text:'PVPP | g/hl', diario: [], mensal:[], active:false}
                    ]
        
        
      }
  } 

  componentDidMount(){     
    
  }
  
  pesquisar(){
    
    PubSub.publish('pesquisarprocesso', {ano:this.state.ano,mes:this.state.mes,filial:this.state.filial,rota:'processo'});

  }
  
  
  alteraMes(e, {
    value,
    text
  }) {
    this.setState({
      mes: value
    })
  }
  
  alteraAno(e, {
    value,
    text
  }) {
    this.setState({
      ano: value
    })
  }

  alteraFilial(e,{value,text}){
    this.setState({
      filial:value
    })
  }

  render() {
    
    return (        
         <Segment style={{padding:'10px'}}>      
            <Segment clearing attached>
                        <Menu secondary>
                            <Menu.Menu position='right'>
                              <Menu.Item>
                                <Select placeholder='Selecione uma filial' options={this.state.filiais} style={{width:'250px', marginRight:'10px'}} onChange={this.alteraFilial.bind(this)}/>
                                <Select placeholder='Selecione um ano' options={this.state.anos} style={{width:'50px', marginRight: '10px'}} onChange={this.alteraAno.bind(this)}/>
                                <Select placeholder='Selecione um mês' options={this.state.meses} style={{width:'250px', marginRight: '10px'}} onChange={this.alteraMes.bind(this)}/>
                                <Button onClick={this.pesquisar.bind(this)} color='yellow' floated = 'right' icon style={{marginRight: '10px'}} ><Icon name='search'/> Pesquisar </Button>                                  
                              </Menu.Item>
                            </Menu.Menu>
                        </Menu>
            </Segment>           
            <Segment clearing attached='bottom' style={{padding:'5px'}}>
              <Dimmer inverted>
                <Loader size='large'></Loader>
              </Dimmer>    
             {this.state.indicadores.map(item =>
                <Grafico key={item.value} indicador={item.value} titulo={item.text} diario={item.diario} mensal={item.mensal} active={item.active} relatorio='processo' />   
             )}   
            </Segment>
         </Segment>                  
    );
  }
}
                                  
export default Main;
