import React, { Component } from 'react';
import 'amcharts3/amcharts/amcharts';
import 'amcharts3/amcharts/serial';
import 'amcharts3/amcharts/themes/light';
import AmCharts from "@amcharts/amcharts3-react";
import { Header, Segment, Icon,   Dimmer, Loader,  Grid, Card } from 'semantic-ui-react';

import PubSub from 'pubsub-js';
var request = require("request");

const formatCurrency = require('format-currency');
let opts = { locale: 'de-DE' };

class Grafico extends Component{
  constructor(props){
    super(props);
      this.state = {
        diario:[],
        mensal:[],
        active:false
      }
    
  }
  
  componentDidMount(){
      PubSub.subscribe('pesquisar'+this.props.relatorio, function (topic, data) {      
      
       this.setState({active:true})
    
      var indicador = { method: 'GET',
        url: 'http://191.252.186.179:5050/brainydataind/'+data.rota+'/diario/'+data.ano+'&'+data.mes+'&'+data.filial+'&'+this.props.indicador,
        headers: {} 
      };
      
      request(indicador, function (error, response, body) {
          if (error){
            console.log(error)
          }else{
            var a = JSON.parse(body);
            this.setState({diario: a,active:false});      
        }
      }.bind(this));
        
      var indicador_mensal = { method: 'GET',
        url: 'http://191.252.186.179:5050/brainydataind/'+data.rota+'/mensal/'+data.ano+'&'+data.mes+'&'+data.filial+'&'+this.props.indicador,
        headers: {} 
      };
      
        
       request(indicador_mensal, function (error, response, body) {
          if (error){
            console.log(error)
          }else{
            var a = JSON.parse(body);
            this.setState({mensal:a})
        }
      }.bind(this));
        
        
        
      }.bind(this));
  }
  
  getConfig(){
    
     let config ={
          "type": "serial",
          "numberFormatter": {precision: 2, decimalSeparator: ',', thousandsSeparator: '.'},
          "categoryField": "DIA",
          "startDuration": 0,
          "categoryAxis": {
            "gridPosition": "start",
            "autoWrap":false, 
            "minHorizontalGap":0
          },
          "trendLines": [],
          "graphs": [
            {
              "balloonText": "[[title]] of [[category]]:[[value]]",
              "fillAlphas": 1,
              "fillColors": "#fbbd08",
              "showBalloon": false,
              "id": "AmGraph-1",
              "labelText": "[[value]]",
               "labelFunction": function(graphDataItem, graph){
                    var value = graphDataItem.values.value;
                    value = formatCurrency(value,opts);
                    return value;
              },
              "lineAlpha": 0,
              "showAllValueLabels": true,
              "tabIndex": 0,
              "title": "graph 1",
              "type": "column",
              "valueField": "VALOR"
            },
            {
              "balloonText": "[[title]] of [[category]]:[[value]]",
              "id": "AmGraph-2",
              "lineColor": "#FF0000",
              "showBalloon": false,
              "lineThickness": 5,
              "title": "graph 2",
              "valueField": "META"
            }
          ],
          "guides": [],
          "valueAxes": [
            {
              "id": "ValueAxis-1",
              "title": "",
               "minimum": 0
            }
          ],
          "allLabels": [],
          "balloon": {},
          "legend": {
            "enabled": false,
            "useGraphSettings": true
          },
          "titles": [
            {
              "text": ""
            }
          ],
          "dataProvider": this.state.diario
        };
    return config;
    
  }
  render() {
    
    let config = this.getConfig();   
    let indicador_mensal = (this.state.mensal.length>0) ? Math.round(this.state.mensal[0].VALOR*100)/100 : '' ;
    let meta_mensal = (this.state.mensal.length>0) ? Math.round(this.state.mensal[0].META*100)/100 : '' ;
    let um = (this.state.mensal.length>0) ? this.state.mensal[0].UM : '' ;
      
    let icone = (this.state.mensal.length === 0) ? 'minus' : ((this.state.mensal.length>0 &&  indicador_mensal>meta_mensal ) ? 'thumbs down' : 'thumbs up') ;
    let  icone_cor = (this.state.mensal.length === 0) ? 'grey' : ((this.state.mensal.length>0 &&  indicador_mensal>meta_mensal ) ? 'red' : 'green') ;
    
    let gr =  <AmCharts.React style={{ width: "100%", height: "200px" }} options={config} />
    
  
    return (        
        <div style={{marginBottom:'10px'}}>
            <Segment clearing attached='top'>
              <Header as='h5' floated='left' style={{marginTop:'5px',marginBottom:'0px'}}>                
                {this.props.titulo}
              </Header>     
            </Segment>
            <Segment clearing attached='bottom' style={{padding:'5px'}}>
              <Dimmer  active = {this.state.active} inverted>
                <Loader size='large'></Loader>
              </Dimmer> 
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column width={14}>
                   {gr} 
                  </Grid.Column>
                  <Grid.Column width={2}>
                   <Card style={{ height: "200px"}}>                      
                      <Card.Content textAlign='center'>
                         <Icon name={icone} color={icone_cor} size='huge' />                      
                        
                      </Card.Content>
                      <Card.Content extra textAlign='center'>
                         <Card.Header>{indicador_mensal} {um}</Card.Header>
                         <Card.Meta>Valor</Card.Meta>
                      </Card.Content>
                      <Card.Content extra textAlign='center'>
                         <Card.Header>{meta_mensal} {um}</Card.Header>
                         <Card.Meta>Meta</Card.Meta>
                      </Card.Content>
                    </Card>                    
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>                 
      </div>
    );
  }
}

export default Grafico;