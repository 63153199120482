import React , { Component } from 'react'
import {  Image,  Segment, Menu, Sidebar } from 'semantic-ui-react'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";

import logo from '../img/brainydata.png'

import RelatorioEnvaseLata from '../areas/e_envase/linha_de_lata.js'
import RelatorioEnvaseVidro from '../areas/e_envase/linha_de_vidro.js'
import RelatorioProcesso from '../areas/a_processo/processo.js'

import Fechamento from '../gerais/fechamento/main.js'

import PubSub from 'pubsub-js';

class Principal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      componente: '',
      visible:false,
      tabs: [],
      menuAreas: [
        {
          item: 'processo',
          descricao: 'Processo',
          subArea:[  
            {
              item:'processo',
              descricao:'Processo'
            }
          ]
        },
        {
          item: 'envase',
          descricao: 'Envase',
          subArea:[
            {
              item:'linhaLata',
              descricao:'Linha de Lata'
            },
            {
              item:'linhaGarrafa',
              descricao:'Linha de Garrafa'
            },
            {
              item:'',
              descricao:''
            }
          ]
          
        }
      ],
      menuGeral: [
        {
          item: 'gerais',
          descricao: 'Gerais',
          subArea:[{
             item: 'fechamento',
             descricao: 'Rotina de Fechamento'            
          }]
        }
      ],
      menu: []
    }
  }  
  
  componentDidMount(){
    
  }
 
  logout(){
    PubSub.publish('autenticacao', { validado:false });    
  }
  
  handleButtonClick(e,{name}){
    
      if(name === 'gerais'){
        this.setState({menu:this.state.menuGeral})
       }else if(name === 'areas'){
         this.setState({menu:this.state.menuAreas})
       }
    
    this.setState({ visible: true })
  }

  handleSidebarHide(){
    this.setState({ visible: false });
  }

  addTab(e,{name,value}){    
    let tabsClone = this.state.tabs;
    
    if(value === 'linhaLata'){
        tabsClone.push({ menuItem: name, render: <RelatorioEnvaseLata style={{height:'800px'}} attached={false}/>});
        }else if(value === 'linhaGarrafa'){
          tabsClone.push({ menuItem: name, render: <RelatorioEnvaseVidro usuario={this.props.usuario} style={{height:'800px'}} attached={false}/>});
        }else if(value === 'processo'){
          tabsClone.push({ menuItem: name, render: <RelatorioProcesso usuario={this.props.usuario} style={{height:'800px'}} attached={false}/>});
        }else if(value === 'fechamento'){
          tabsClone.push({ menuItem: name, render: <Fechamento usuario={this.props.usuario} style={{height:'800px'}} attached={false}/>});
        }else{
        tabsClone.push({ menuItem: name, render: <Segment style={{height:'800px'}} attached={false}>{name}</Segment>});     
       }
   
    this.setState({tabs:tabsClone});     
  }
  
  getSubItemMenu(item){
    let self = this;
    
    return item.subArea.map(function (subitem){ return (<Menu.Item as='a'  key={subitem.item} name={subitem.descricao}  value={subitem.item}  style={{textAlign:'left'}}  onClick={self.addTab.bind(self)}>{subitem.descricao}</Menu.Item>) })
    
  }
  
  render() {
    
   // let componenteRender = ''
    if(this.state.componente === 'apontamento'){
     // componenteRender = <Apontamento usuario={this.props.usuario}/>
    }
      
    
    let { visible } = this.state;
    //let panes = this.state.tabs;

    return (
       
      <div>
       <Menu stackable>      
        <Menu.Item>          
          <Image src={logo} size='small' />
        </Menu.Item>      
        <Menu.Item name='gerais' onClick={this.handleButtonClick.bind(this)}>
          Gerais
        </Menu.Item>
        <Menu.Item name='areas' onClick={this.handleButtonClick.bind(this)}>
          Áreas
        </Menu.Item>
      </Menu>      
      <Sidebar.Pushable as={Segment}>
          <Sidebar
            as={Menu}
            animation='overlay'
            icon='labeled'
            direction='left'
            vertical
            onHide={this.handleSidebarHide.bind(this)}            
            visible={visible}
            width='wide'>  
              {this.state.menu.map(function(item){
                return( <Menu.Item key={item.item}>      
                          <Menu.Header style={{textAlign:'left'}}>{item.descricao}</Menu.Header>  
                          <Menu.Menu>
                            {this.getSubItemMenu(item)}             
                          </Menu.Menu>
                       </Menu.Item>)               
              }.bind(this))}
          </Sidebar>

          <Sidebar.Pusher dimmed={visible}>
            <Segment basic style={{height:'2000px'}}>               
                <Tabs>
                  <TabList>
                    {this.state.tabs.map(function(item){
                      return( <Tab key={item.menuItem}>{item.menuItem}</Tab>)               
                    })}
                  </TabList>
                    {this.state.tabs.map(function(item){
                      return( 
                        <TabPanel key={Math.random()} forceRender={true}>
                        {item.render}
                        </TabPanel>)               
                    })}
          
                </Tabs>
            </Segment>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </div>           
)
  }
}
  
  export default Principal
