import React, { Component } from 'react';
import './App.css';
import PubSub from 'pubsub-js';

import 'semantic-ui-css/semantic.min.css';

import Login from './autenticacao/login'
import Principal from './principal/principal'

class App extends Component {
  constructor(props){
    super(props);
    this.state = {component:'login',usuario:''}
  }
  
  componentDidMount(){      
    PubSub.subscribe('autenticacao', function (topic, data) {                
      if(data.validado === true){
        this.setState({component:'principal',usuario:data.usuario})
      }else if(data.validado === false){
        this.setState({component:'login'})
      }
    }.bind(this));        
  }
  
  render() {
          if (this.state.component === 'login') {
            return (<Login/>);
          } else if (this.state.component === 'principal') {
            return (<Principal usuario={this.state.usuario}/>);
          }    
  }
}
 
export default App;
