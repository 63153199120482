import React, { Component } from 'react';

import { Table, Header,  Segment, Icon, Modal, Button, Dimmer, Loader, Menu, Select, TextArea, Form } from 'semantic-ui-react';


var request = require("request");

var dedupe = require('dedupe');

var timediff = require('timediff');

class Main extends Component {

  constructor(props){
    super(props);
      this.state = {        
        inicioRequisicao:'',         
        request:false,
        filiais:[],
        tempo:[],
        anos:[],
        meses:[],
        filial:'',
        ano:'',
        mes:'',
        periodo:[],
        comentario:''
      }
  } 
  
  componentDidMount(){     
    
    var tempo = { method:'GET',
                 url:'http://191.252.186.179:4050/apontamento/tempo',
                 headers:{}
                };
    
    request(tempo, function (error, response, body) {
      if (error){
        console.log(error)
      }else{
        var a = JSON.parse(body);            
        
        var anos = a.map(function(item){return {key:item.ANO,value:item.ANO,text:item.ANO}})
        var meses = a.map(function(item){return {key:item.MES_EXT,value:item.MES_EXT,text:item.MES_EXT}})
        
        anos = dedupe(anos);
        meses = dedupe(meses);
        
        this.setState({tempo:a,anos:anos,meses:meses});      
      }
    }.bind(this));
    
    
    var filiais = {
      method:'GET',
      url:'http://191.252.186.179:5050/brainydataind/dadomestre/filial',
      headers:{}
    }
    
    request(filiais, function (error, response, body) {
      if (error){
        console.log(error)
      }else{
        var a = JSON.parse(body);            
        
        a = a.map(function(item){return {key:item.COD_FILIAL,value:item.COD_FILIAL,text:item.DESC_FILIAL}}) 
        
        this.setState({filiais:a});      
      }
    }.bind(this));
    
    
  }
  
  alteraDados(){
    
    var filial = this.state.filial;
    var ano = this.state.ano;
    var mes = this.state.mes;   
    
    var atualizacoes = {
      method:'GET',
      url:'http://191.252.186.179:5050/brainydataind/etl/atualizacao/'+filial+'&'+ano+'&'+mes,
      headers:{}
    }
    
    request(atualizacoes, function (error, response, body) {
      if (error){
        console.log(error)
      }else{
        var a = JSON.parse(body);    
              
        a = a.filter(function(currentValue, index, arr) {
          let x;
          if (index !== a.length-1) {
            x =  currentValue.ID_TEMPO !== arr[index + 1].ID_TEMPO
          } else if (index === a.length-1) {
            x =  currentValue
          }
          return x;
        })        
        
        for (var o in a) {
          if (a[o].key === null) {
            a[o].disabled = true
          } else {
            a[o].disabled = false
          }
        }
        
        for(var k in a){
          if(a[k].EVENTO === 'IMPORTAÇÃO'){
            a[k].disabledImport = true;           
             }else{
            a[k].disabledImport = false;           
             }
        }
        
        this.setState({periodo:a}); 
        this.setState({request:false});
      }
    }.bind(this));
        
  }
  
  
  alteraMes(e, {
    value,
    text
  }) {
    this.setState({
      mes: value
    },this.alteraDados)
  }
  
  alteraAno(e, {
    value,
    text
  }) {
    this.setState({
      ano: value
    },this.alteraDados)
  }
  
  alteraFilial(e, {
    value,
    text
  }) {
    this.setState({
      filial: value
    },this.alteraDados)
  }
  
/*Inicia processo de importação de dados*/
importaDados(e,{value}){  
    
    this.setState({request:true});
    
    this.setState({inicioRequisicao:new Date()});
    
  
    if(this.state.filial === '302030'){      
      this.producaoETL(value.ID_TEMPO,value.ID_TEMPO)
    }else{      
      
    }
}
/*******************************************************/
  
/*Importa dados de produção*/
producaoETL(i,f){
  
  var config = { 
                 method:'GET',
                 url:'http://191.252.186.179:5050/brainydataind/etl/apontamento/etl/producao/'+i+'&'+f,
                 headers:{}
                };
  
   request(config, function(error, response, body) {
      if (error) throw new Error(error);
     
      
      var p = JSON.parse(body);     
      for(var z in p){
         var config_ = { 
                 method:'POST',
                 url: 'http://191.252.186.179:5050/brainydataind/dadomestre/fato/producao',
                 headers: {
                  'content-type': 'application/json'
                  },
                  body: p[z],
                  json: true
                };
        
         request(config_, function(error, response, body) {
            if (error) throw new Error(error);       
          });        
      }
     
      this.consumoETL(i,f)
    }.bind(this)); 
  
}
/*******************************************************/  
  
/*Importa dados de consumo*/
consumoETL(i,f){
  var config = { method:'GET',
                 url:'http://191.252.186.179:5050/brainydataind/etl/apontamento/etl/consumo/'+i+'&'+f,
                 headers:{}
                };
  request(config, function(error, response, body) {
      if (error) throw new Error(error);
      
    
      var p = JSON.parse(body);     
        for(var z in p){
           var config_ = { 
                   method:'POST',
                   url: 'http://191.252.186.179:5050/brainydataind/dadomestre/fato/consumo',
                   headers: {
                    'content-type': 'application/json'
                    },
                    body: p[z],
                    json: true
                  };

           request(config_, function(error, response, body) {
              if (error) throw new Error(error);       
            });        
        }
    
    
      this.requisicoesETL(i,f)
    }.bind(this)); 
}
/*******************************************************/
  
/*Importa dados de requisições*/
requisicoesETL(i,f){
  var config = { method:'GET',
                 url:'http://191.252.186.179:5050/brainydataind/etl/apontamento/etl/requisicoes/'+i+'&'+f,
                 headers:{}
                };
  request(config, function(error, response, body) {
      if (error) throw new Error(error);
      
    
      var p = JSON.parse(body);     
        for(var z in p){
           var config_ = { 
                   method:'POST',
                   url: 'http://191.252.186.179:5050/brainydataind/dadomestre/fato/requisicoes',
                   headers: {
                    'content-type': 'application/json'
                    },
                    body: p[z],
                    json: true
                  };

           request(config_, function(error, response, body) {
              if (error) throw new Error(error);     
             
            });        
        }
     
    this.finalizaImportacaoDados(i)
    
    }.bind(this)); 
}
/*******************************************************/
  
/*Registra final de importação*/
finalizaImportacaoDados(value){
    
   
    
    let ano = new Date().getFullYear();
    let mes = "0"+new Date().getMonth();
        mes = (mes.length === 3)? mes.substring(1,3) : mes;
    
    let dia = "0"+new Date().getDate();
        dia = (dia.length === 3)? dia.substring(1,3) : dia; 
    
    let hora = "0"+new Date().getHours();
        hora = (hora.length === 3)? hora.substring(1,3) : hora; 
    
    let minuto = "0"+new Date().getMinutes();
        minuto = (minuto.length === 3)? minuto.substring(1,3) : minuto; 
    
    let segundo = "0"+new Date().getSeconds();
        segundo = (segundo.length === 3)? segundo.substring(1,3) : segundo; 
    
     var self = { key: Math.round(Math.random()*100000000),
                  COD_FILIAL:this.state.filial,
                  ID_TEMPO:value,
                  DATA_ATUALIZACAO: dia+"/"+mes+"/"+ano+" "+hora+":"+minuto+":"+segundo,
                  USUARIO:this.props.usuario,
                  EVENTO:'IMPORTAÇÃO',
                  COMENTARIO:'',
                  DURACAO:timediff( this.state.inicioRequisicao,new Date(), 'S').seconds
                };
    
     var options = {
      method: 'POST',
      url: 'http://191.252.186.179:5050/brainydataind/etl/atualizacao/',
      headers: {
        'content-type': 'application/json'
      },
      body: self,
      json: true
    };

    request(options, function(error, response, body) {
      if (error) throw new Error(error);
     
      this.alteraDados();
    }.bind(this));      
  }
/*******************************************************/
  
/**/ 
excluirDados(e,{value}){    
  
    this.setState({request:true});  
    
    this.setState({inicioRequisicao:new Date()});
     
    this.excluirProducaoETL(value);
  
  }
/*******************************************************/
  
/**/
excluirProducaoETL(dt){
  
  var options = {
    method: 'DELETE',
    url: 'http://191.252.186.179:5050/brainydataind/dadomestre/fato/producao',
    headers:{ 'content-type': 'application/json' },
    body: { ID_TEMPO: dt.ID_TEMPO, COD_FILIAL: this.state.filial },
    json: true
  };

  request(options, function (error, response, body) {
    if (error) throw new Error(error);
    this.excluirConsumoETL(dt);
  }.bind(this));
}
  /*******************************************************/
  
/**/
excluirConsumoETL(dt){
  
  var options = {
    method: 'DELETE',
    url: 'http://191.252.186.179:5050/brainydataind/dadomestre/fato/consumo',
    headers:{ 'content-type': 'application/json' },
    body: { ID_TEMPO: dt.ID_TEMPO, COD_FILIAL: this.state.filial },
    json: true
  };

  request(options, function (error, response, body) {
    if (error) throw new Error(error);
    this.excluirRequisicoesETL(dt);
  }.bind(this));
    
}
/*******************************************************/

/**/
excluirRequisicoesETL(dt){
  
  var options = {
    method: 'DELETE',
    url: 'http://191.252.186.179:5050/brainydataind/dadomestre/fato/requisicoes',
    headers:{ 'content-type': 'application/json' },
    body: { ID_TEMPO: dt.ID_TEMPO, COD_FILIAL: this.state.filial },
    json: true
  };

  request(options, function (error, response, body) {
    if (error) throw new Error(error);
    this.finalizaExclusaoDeDados(dt.ID_TEMPO)
  }.bind(this));
    
}
/*******************************************************/
  
finalizaExclusaoDeDados(value){
  
      
    let ano = new Date().getFullYear();
    let mes = "0"+new Date().getMonth();
        mes = (mes.length === 3)? mes.substring(1,3) : mes;
    
    let dia = "0"+new Date().getDate();
        dia = (dia.length === 3)? dia.substring(1,3) : dia; 
    
    let hora = "0"+new Date().getHours();
        hora = (hora.length === 3)? hora.substring(1,3) : hora; 
    
    let minuto = "0"+new Date().getMinutes();
        minuto = (minuto.length === 3)? minuto.substring(1,3) : minuto; 
    
    let segundo = "0"+new Date().getSeconds();
        segundo = (segundo.length === 3)? segundo.substring(1,3) : segundo; 
    
     var self = { key: Math.round(Math.random()*100000000),
                  COD_FILIAL:this.state.filial,
                  ID_TEMPO:value,
                  DATA_ATUALIZACAO: dia+"/"+mes+"/"+ano+" "+hora+":"+minuto+":"+segundo,
                  USUARIO:this.props.usuario,
                  EVENTO:'EXCLUSÃO',
                  COMENTARIO:'',
                  DURACAO:timediff( this.state.inicioRequisicao ,new Date(), 'S').seconds
                };
    
     var options = {
      method: 'POST',
      url: 'http://191.252.186.179:5050/brainydataind/etl/atualizacao/',
      headers: {
        'content-type': 'application/json'
      },
      body: self,
      json: true
    };

    request(options, function(error, response, body) {
      if (error) throw new Error(error);
      this.alteraDados();
    }.bind(this));  
    
}
/*******************************************************/
  
/*Atualiza comentário no state*/
atualizaComentario(e, {value}){
  this.setState({comentario:value})
}
/*******************************************************/  
  
  /*Adiciona comentário em um log de atualização*/
  adicionaComentario(e,{value}){
    
    let self = value;
    self.COMENTARIO = this.state.comentario;
    self.USUARIO = this.props.usuario;
    
    var options = {
      method: 'PUT',
      url: 'http://191.252.186.179:5050/brainydataind/etl/atualizacao/',
      headers: {              
        'content-type': 'application/json'
      },
      body: self,
      json: true
    };

    request(options, function(error, response, body) {
      if (error) throw new Error(error);     

    }); 
    
   this.setState({comentario: ''})

  }
  /*******************************************************/
  
  render() {    
    return (        
         <Segment style={{padding:'10px'}}> 
           <Segment clearing attached>
                        <Menu secondary>
                            <Menu.Menu position='right'>
                              <Menu.Item>
                                <Select placeholder='Selecione uma filial' options={this.state.filiais} style={{width:'50px', marginRight: '10px'}} onChange={this.alteraFilial.bind(this)} />
                                <Select placeholder='Selecione um ano' options={this.state.anos} style={{width:'50px', marginRight: '10px'}} onChange={this.alteraAno.bind(this)}/>
                                <Select placeholder='Selecione um mês' options={this.state.meses} style={{width:'250px', marginRight: '10px'}} onChange={this.alteraMes.bind(this)}/>                                   
                              </Menu.Item>
                            </Menu.Menu>
                        </Menu>
            </Segment>
            <Segment clearing attached='top'>
              <Header as='h5' floated='left' style={{marginTop:'5px',marginBottom:'0px'}}>                
                Rotina de Fechamento
              </Header>     
            </Segment>
            <Segment clearing attached='bottom' style={{padding:'5px'}}>                  
              <Dimmer active={this.state.request} inverted>
                <Loader size='large'></Loader>
              </Dimmer>  
              <Table size='small' textAlign='center' celled structured attached striped compact style={{width:'100%'}}>
                  <Table.Header>
                    <Table.Row>                      
                      <Table.HeaderCell style={{width:'200px'}}>Data</Table.HeaderCell>    
                      <Table.HeaderCell style={{width:'200px'}}>Última Atualização</Table.HeaderCell>  
                      <Table.HeaderCell style={{width:'200px'}}>Evento</Table.HeaderCell>                        
                      <Table.HeaderCell>Comentário</Table.HeaderCell>    
                      <Table.HeaderCell style={{width:'10px'}}><Button size='tiny' icon='plus square' disabled/></Table.HeaderCell>
                      <Table.HeaderCell style={{width:'10px'}}><Button size='tiny' icon='cloud download' disabled/></Table.HeaderCell>     
                      <Table.HeaderCell style={{width:'10px'}}><Button size='tiny' icon='trash alternate' disabled /></Table.HeaderCell>    
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                  {this.state.periodo.map(item =>
                    <Table.Row key={item.ID_TEMPO}>                      
                      <Table.Cell>{item.DATA}</Table.Cell>
                      <Table.Cell>{item.DATA_ATUALIZACAO}</Table.Cell>
                      <Table.Cell>{item.EVENTO}</Table.Cell>                      
                      <Table.Cell style={{textAlign:'justify'}}>{item.COMENTARIO}</Table.Cell>
                      <Table.Cell>                        
                           <Modal trigger={<Button size='tiny' icon='plus square' disabled={item.disabled} color='teal' />}>
                            <Modal.Header>Comentário | {item.DATA}</Modal.Header>
                            <Modal.Content image>                              
                              <Modal.Description>
                                <Header as='h5'>Adicione algum comentário relevante sobre essa atualização:</Header>
                                <Form>
                                  <TextArea rows={5} placeholder='Comentário' onChange={this.atualizaComentario.bind(this)} value={this.state.comentario}/>
                                </Form>
                              </Modal.Description>
                            </Modal.Content>
                            <Modal.Actions>
                              <Button color='yellow' value={item} onClick={this.adicionaComentario.bind(this)}>
                                <Icon name='save'/> Salvar
                              </Button>
                            </Modal.Actions>
                          </Modal>
                      </Table.Cell>
                      <Table.Cell><Button size='tiny' icon='cloud download' color='yellow' disabled={item.disabledImport} onClick={this.importaDados.bind(this)} value={item} /></Table.Cell>
                      <Table.Cell><Button size='tiny' icon='trash alternate' color='red' disabled={item.disabled} onClick={this.excluirDados.bind(this)} value={item}/></Table.Cell>
                    </Table.Row>
                  )}
                  </Table.Body>                  
                </Table>
            </Segment>
         </Segment>                  
    );
  }
}
                                  
export default Main;
